<template>
  <div class="ticket">
    <barcode class="barcode" :text="ticket.capacitySegment.showtime.showId + '-' + ticket.registrationId" />
    <ticket-block :color="ticket.capacitySegment.showtime.ticketColor" :label="sharedBlock" />
    <ticket-block :color="ticket.capacitySegment.showtime.ticketColor" :label="sharedBlock" />
    <!-- <img class="snr-bg" src="../assets/images/ml23-snr-ticket-full.png" /> -->
    <svg class="wordplay" version="1.1" x="0px" y="0px" width="1.5in" height="1.5in" viewBox="0 0 125.58 125.59">
      <path :fill="'black'" d="M33.91,85.51l4.08-2.88v-21.46h-16.33v64.42h50.61v-14.97h-34.28v-9.94l-4.08,2.81v-17.98ZM33.91,22.1v7.7c.79-.31,1.47-.71,2.04-1.2,1.04-.89,1.74-2.11,2.11-3.64l-4.14-2.86ZM38.05,24.95l13.93,9.6c2.18-3.4,3.27-7.55,3.27-12.45,0-6.94-2.13-12.36-6.39-16.26C44.61,1.95,38.65,0,30.98,0H0v71.94h16.33v-27.73h14.65c2.77,0,5.32-.26,7.66-.79l-4.73-3.34v-10.29c-1.43.57-3.23.86-5.38.86h-12.19V13.66h12.19c3.34,0,5.81.7,7.42,2.11,1.6,1.41,2.41,3.55,2.41,6.44,0,1.01-.1,1.92-.3,2.75M66.04,62.79l-5.58,3.95v-7.89l5.58,3.94ZM66.68,75.76l4.82,1.81,21.44-14.79-40.96-28.23c-.88,1.38-1.93,2.63-3.17,3.76-2.72,2.5-6.12,4.2-10.18,5.11l7.64,5.4v27.94l-8.3,5.87v18.06l18.77-12.95-4.91-1.78,14.83-10.2ZM110.41,93.33l3.59-48.5-15.56,10.68-2.01,31.44-24.93-9.38-14.74,10.16,39.95,14.48,15.18,23.37,13.7-8.89-15.18-23.37Z" />
    </svg>
    <div class="ticket-body">
      <h1 :style="`color: ${ticket.capacitySegment.showtime.ticketColor}`" v-html="ticket.capacitySegment.showtime.show.ticketTitle"></h1>
      <!-- <h2 v-html="locationBlock" /> -->
       <h2 v-html="locationBlock" />
      <h3><v-runtime-template :template="`<span>${copyTemplate}</span>`" /></h3>
    </div>
  </div>
</template>

<script>
import { formatInTimeZone } from "date-fns-tz";

import VRuntimeTemplate from 'v-runtime-template';
import TicketBlock from './TicketBlock';
import Barcode from './Barcode';

export default {
  name: 'Ticket',
  components: { VRuntimeTemplate, TicketBlock, Barcode },
  props: {
    badgeNameFull: String,
    ticket: Object,
    boardingGroups: {
      type: Object,
      default() {
        return {
          monday: 'C',
          wednesday: 'C'
        }
      }
    },
  },
  computed: {
    boardingGroup() {
      return this.ticket.capacitySegment.showtime.show.id === 10 ? this.boardingGroups.monday : this.boardingGroups.wednesday;
    },
    dayBlock() {
      if( this.ticket.capacitySegment.showtime.timeDisplayOverride !== null ) {
        return this.ticket.capacitySegment.showtime.timeDisplayOverride;
      }
      
      // EEE for 3-day. EEEE for full day.
      
      return formatInTimeZone(new Date(this.ticket.capacitySegment.showtime.startTime), 'America/Los_Angeles', 'EEE').toUpperCase();
    },
    timeBlock() {
      if( this.ticket.capacitySegment.showtime.timeDisplayOverride !== null ) {
        return this.ticket.capacitySegment.showtime.timeDisplayOverride;
      }
      
      let timeBlock = formatInTimeZone(new Date(this.ticket.capacitySegment.showtime.startTime), 'America/Los_Angeles', 'h:mm aa');
      if(this.ticket.capacitySegment.showtime.show.usesBoarding === 1) {
        timeBlock = timeBlock + '&nbsp;&nbsp;' + this.boardingGroup;
      }
      return timeBlock;
    },
    sharedBlock() {
      return this.dayBlock + '<br />' + this.timeBlock;
    },
    locationBlock() {
      let locationBlock = formatInTimeZone(new Date(this.ticket.capacitySegment.showtime.startTime), 'America/Los_Angeles', "EEEE 'at' h:mm aa");
      locationBlock += '<br />';
      locationBlock += this.ticket.capacitySegment.showtime.show.venueName;

      if(this.ticket.capacitySegment.showtime.show.usesBoarding === 1) {
        locationBlock += '<br />';
        locationBlock += 'Boarding Group ' + this.boardingGroup;
      }
      return locationBlock;
    },
    copyTemplate() {
      return this.ticket.capacitySegment.showtime.ticketCopy ? this.ticket.capacitySegment.showtime.ticketCopy : 'Present this ticket for admission.';
    }
  },
  methods: {
    formatInTimeZone,
    
  }
}
</script>

<style scoped>
.ticket {
  width: 4.75in;
  height: 1.75in;
  margin: 0.125in;
  position: inherit;
}
.wordplay {
  width: 1.5in;
  height: 1.5in;
  position: absolute;
  top: 0;
  left: 1.25in;
  margin: 0.125in 0.5625in;
  opacity: 0.04;
}
.ticket-body {
  width: 2.625in;
  height: 1.75in;
  position: absolute;
  top: 0;
  left: 1.25in;
  text-align: center;
}
h1 {
  font-family: 'Alegraya';
  font-size: 20pt;
  line-height: 20pt;
  margin-top: 10px;
}
h2 {
  font-family: 'Alegraya';
  font-size: 12pt;
  font-weight: normal;

  position: absolute;
  width: 100%;
  top: 0.6in;
}
h3 {
  font-family: 'Alegraya Sans Italic';
  font-size: 10pt;
  font-weight: normal;

  position: absolute;
  width: 100%;
  bottom: 0in;
  margin-bottom: 8px;
}
.barcode {
  width: 1.75in;
  height: 0.25in;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(90deg) translate(0, -100%);
  transform-origin: top left;
}
</style>