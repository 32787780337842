<template>
  <div class="ticket-block">
    <svg version="1.1" x="0px" y="0px" width="0.875in" height="1.75in" viewBox="0 0 63 126">
      <!-- <path :fill="color" d="M0,0V45A18,18,0,0,1,18,63,18,18,0,0,1,0,81v45H63V0Z"/> -->
      <path :fill="color" d="M0,126c34.79,0,63-28.21,63-63S34.79,0,0,0v126Z"/>
    </svg>
    <div class="ticket-block-label" v-html="label" />
  </div>
</template>

<script>
export default {
  name: 'TicketBlock',
  props: {
    color: String,
    label: String,
  }
}
</script>

<style scoped>
.ticket-block {
  width: 0.875in;
  height: 1.75in;
  position: absolute;
  left: 0.375in;
}
.ticket-block:nth-of-type(2) {
  left: auto;
  right: 0;
  transform: rotate(180deg);
}
.ticket-block-label {
  position: absolute;
  top: 0in;
  left: 0.125in;
  width: 1.75in;
  height: 0.6in;
  transform: rotate(90deg) translate(0, -100%);
  transform-origin: top left;
  color: white;
  line-height: 0.3in;
  font-family: 'Alegraya';
  font-weight: 400;
  font-size: 22pt;
}
</style>