<template>
    <div v-html="barcodeContents">
    </div>
  </template>
  
  <script>
  import bwipjs from 'bwip-js';

  export default {
    name: 'Barcode',
    props: {
      color: String,
      text: String,
    },
    data() {
        return {
            barcodeContents: '',
        }
    },
    methods: {
        generateBarcode() {
            let svg = bwipjs.toSVG({
                bcid:        'code128',       // Barcode type
                text:        this.text,    // Text to encode
                height:      25.4,              // Bar height, in millimeters
                includetext: false,            // Show human-readable text
                textxalign:  'center',        // Always good to set this
            });

            return svg;
        }
    },
    created() {
        // Generate the barcode
        this.barcodeContents = this.generateBarcode();
    }

  }
  </script>

<style scoped>
.barcode {
  overflow: hidden;
}
</style>